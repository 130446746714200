import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FacilitiesService }      from 'src/app/facilities/facilities.service';
import { eventsService }      from 'src/app/events/events.service';
import { CourtsService }      from 'src/app/courts/courts.service';
import { ReservationsService }      from 'src/app/reservations/reservations.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  isAdmin = false;
  isClient = false;
  isHome = false;
  username = null;
   constructor(
   public facilitiesService: FacilitiesService,
   public eventsService: eventsService,
   public courtsService: CourtsService,
   public reservationsService: ReservationsService,
   ) {
    if(localStorage.getItem('logintoken') !== null){
      this.isLoggedIn = true;
    }
    if(localStorage.getItem('username') !== null){
      this.username = localStorage.getItem('username');
    }
    if(localStorage.getItem(btoa("authority")) == btoa("ADMIN")){
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    if(localStorage.getItem(btoa("authority")) == btoa("CLIENT")){
      this.isClient = true;
    } else {
      this.isClient = false;
    }
    this.isHome = localStorage.getItem("isHome") == 'true'
  }
  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login(): Observable<boolean> {
	this.clearData();
    if(localStorage.getItem('username') ){
      this.username = localStorage.getItem('username');
    }
    if(localStorage.getItem(btoa("authority")) == btoa("ADMIN")){
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    if(localStorage.getItem(btoa("authority")) == btoa("CLIENT")){
      this.isClient = true;
    } else {
      this.isClient = false;
    }
    this.isHome = localStorage.getItem("isHome") == 'true'
    return of(true).pipe(
      delay(1000),
      tap(val => this.isLoggedIn = true)
    );
  }

  logout(): void {
	this.clearData();
    this.isLoggedIn = false;
    this.isAdmin = false;
    this.isClient = false;
    this.isHome = false;
    this.username = null;
  }

  async getHome() {
    let data = await this.getUserData();
    for (const d of data.propertyValues) {
      let defaultValue = d.propertyAssignment.property.defaultValue;
      const isRequired = d.required || d.property.required || d.propertyAssignment.required;
      const hasNoDefaultValue = defaultValue == null || defaultValue == 'NONE';
      const hasNoActualValue = d.value == null || d.value == '';
      if (isRequired && hasNoDefaultValue && hasNoActualValue) {
        localStorage.setItem("isHome", '' + (this.isHome = true));
        return '/edit-profile';
      }
    }
    localStorage.setItem("isHome", '' + (this.isHome = false));
    return '/home';
  }

  async getUserData(){
      var self = this;
      var token = localStorage.getItem('logintoken');
      var userdata = localStorage.getItem('userdata');
      var info = JSON.parse(userdata);
      if(this.isAdmin){
        var url = environment.apiUrl+"users/"+info.id;
      } else {
        var url = environment.apiUrl+"users/me";
      }
      if(info){
        const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+token,
            }
         });
        const json = await response.json();
        return json;
      } else {
        return null;
      } 
  }
  
  clearData(){
	  this.facilitiesService.clearData();
	  this.eventsService.clearData();
	  this.courtsService.clearData();
	  this.reservationsService.clearData();
  }
}
