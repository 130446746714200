import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.css']
})
export class ConfirmAccountComponent implements OnInit {

  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,public router: Router) { }
  public formControlMain: FormGroup;
  public message = null;
  public token = null;
  ngOnInit() {
    var self = this;
    this.formControlMain = this.formBuilder.group({
      message: ['', Validators.required]
  });
  this.token = this.route.snapshot.queryParamMap.get("token");
  fetch(environment.apiUrl+"users/confirm-account?token="+this.token, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    }
})
.then(
    function(response) {
        if (response.status !== 200) {
            response.json().then(function(data) {
                self.message='Error during account activation. Please contact administrator.';
            });
            return;
        }
          response.json().then(function(data) {
              self.message='Account successfully activated. Please login to continue.'
            }
            )
        }
)
  }

}
