import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CourtsService {
  public data:any = null;
  constructor() { }

  filterData(data): Observable<boolean> {
    if(data){
      this.data = data;
    }
    return this.data;
  }
  clearData():void {
	  this.data = null;
  }
}
