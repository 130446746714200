import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent }    from './page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InviteComponent } from './invite/invite.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { UserProfileComponent } from './auth/user-profile/user-profile.component';
import { UserProfileWithIdComponent } from './auth/user-profile-with-id/user-profile-with-id.component';
import { UserProfileEditWithIdComponent } from './auth/user-profile-edit-with-id/user-profile-edit-with-id.component';
import { UserProfileEditComponent } from './auth/user-profile-edit/user-profile-edit.component';
import { ConfirmAccountComponent } from './auth/confirm-account/confirm-account.component';
import { ResetAccountComponent } from './auth/reset-account/reset-account.component';

import { AuthGuard }                          from './auth/auth.guard';
import { AdminGuard }                          from './admin/admin.guard';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'term-condition', component: TermConditionComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'user-profile/:id', component: UserProfileWithIdComponent, canLoad: [AdminGuard]},
  { path: 'edit-profile', component: UserProfileEditComponent },
  { path: 'edit-profile/:id', component: UserProfileEditWithIdComponent },
  { path: 'confirm-account', component: ConfirmAccountComponent },
  { path: 'reset-account', component: ResetAccountComponent },
  { path: 'invite/:link', component: InviteComponent },
  {
    path: 'facilities',
    loadChildren: () => import('./facilities/facilities.module').then(mod => mod.FacilitiesModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(mod => mod.eventsModule)
  },
  {
    path: 'courts',
    loadChildren: () => import('./courts/courts.module').then(mod => mod.CourtsModule)
  },
  {
    path: 'resource',
    loadChildren: () => import('./facility-resource/facility-resource.module').then(mod => mod.FacilityResourceModule)
  },
  {
    path: 'reservations',
    loadChildren: () => import('./reservations/reservations.module').then(mod => mod.ReservationsModule)
  },
  {
    path: 'schedule',
    loadChildren: () => import('./schedule/schedule.module').then(mod => mod.ScheduleModule)
  },
  {
    path: 'facilityschedule',
    loadChildren: () => import('./facility-schedule/facility-schedule.module').then(mod => mod.FacilityScheduleModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
    canLoad: [AdminGuard]
  },
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
        preloadingStrategy: SelectivePreloadingStrategyService,
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
