import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { AuthService }      from '../auth/auth.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader } from '@agm/core';
import {Location} from '@angular/common';
import { environment } from '../../environments/environment';
import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {

  	constructor(
      public authService: AuthService, 
      private titleService:Title, 
      private toastr: ToastrService,
      private formBuilder: FormBuilder, 
      public router: Router, 
      private ActivatedRoute: ActivatedRoute,
      private mapsAPILoader: MapsAPILoader, 
      private ngZone: NgZone,
      private _location: Location) {
  		this.titleService.setTitle("Invite");
  	}
  	public link = null;
  	ngOnInit() {
      this.link = this.ActivatedRoute.snapshot.paramMap.get('link');
      if(this.authService.isLoggedIn){
     		if(this.link){
          console.log('das');
          var self = this;
          var token = localStorage.getItem('logintoken');
          fetch(environment.apiUrl+"api/app/event/open?link="+this.link, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token,
          }
        })
        .then(
            function(response) {
              if (response.status !== 200) {
                  response.json().then(function(data) {
                    self.toastr.error(data.message, 'Error');
                });
              return;
              } else {
                response.json().then(function(data) {
                  self.router.navigate(['/events/detail/'+data.id], { queryParams: { link: self.link } });
                });
              }
              
          }
        )
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
        } else {
          this.toastr.error('Link Not Found', 'Error');
        }
    } else {
      this.toastr.error('Please Login or Register first to open this link!', 'Error');
      this.router.navigate(['/']);
    }
  
  }
}
