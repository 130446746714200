import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Router, NavigationExtras } from '@angular/router';
import { AuthService }      from '../auth/auth.service';
import { AdminService }      from '../admin/admin.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {
  baseUrl = environment.baseUrl;
  profileForm = null;
  registerForm: FormGroup;
  forgetForm: FormGroup;
  submitted = false;
  forgetsubmitted = false;
  termcondition = null;
  passwordNotMatch = false;
  constructor(public authService: AuthService,public adminService: AdminService, public router: Router, private toastr: ToastrService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.profileForm = new FormGroup({
      username: new FormControl('', Validators.compose([
          Validators.required,
          //Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])),
      password: new FormControl('',[Validators.required,]),
    });

    this.forgetForm = this.formBuilder.group({
        username: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
    });

    this.registerForm = this.formBuilder.group({
        username: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmpassword: ['', [Validators.required, Validators.minLength(8)]],
    });

    $(document).ready(function(){
      $('.loginSignupshowHide').click(function(){
       $(this).removeClass('active');
      });
    });
  }

  get register() { return this.registerForm.controls; }
  get forget() { return this.forgetForm.controls; }

  logout() {
    localStorage.removeItem('logintoken');
    localStorage.removeItem('userdata');
    localStorage.removeItem(btoa("authority"));
    this.authService.logout();
    this.toastr.success('Logout successfully!', 'success');
    this.router.navigate(['/']);
  }

  adminLogout() {
    localStorage.removeItem('logintoken');
    localStorage.removeItem('userdata');
    localStorage.removeItem(btoa("authority"));
    this.authService.logout();
    this.toastr.success('Logout successfully!', 'success');
    this.router.navigate(['/']);
  }

  moveToSection(sectionId){
    $([document.documentElement, document.body]).animate({
        scrollTop: $("#"+sectionId).offset().top
    }, 1000);
  }

  loginSubmit() {
    var self = this;
    var auth = this.authService;
    var router = this.router;
    var toastr = this.toastr;
    fetch(environment.apiUrl+"users/signin?username="+this.profileForm.value.username+"&password="+this.profileForm.value.password, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(
        function(response) {
          if (response.status !== 200) {
            response.json().then(function(data) {
              toastr.error(data.message, 'Error');
          });
            return;
          }
          response.json().then(function(data) {
            localStorage.setItem('logintoken', data.token);
            localStorage.setItem('username', data.username);
            localStorage.setItem('userdata', JSON.stringify({"id":data.id, "email":data.email}));
            localStorage.setItem(btoa("authority"), btoa(data.roles[0].authority));
            $('#login button.close').trigger('click');
            toastr.success('Login Successfully', 'success');
            auth.login().subscribe(async () => {
              if (auth.isLoggedIn) {
                let redirect = router.parseUrl(auth.redirectUrl ? auth.redirectUrl : await auth.getHome());
                let navigationExtras: NavigationExtras = {
                  queryParamsHandling: 'preserve',
                  preserveFragment: true
                };

                // Redirect the user
                setTimeout(() => router.navigateByUrl(redirect, navigationExtras), 10);
              }
            });
            self.submitted = false;
            self.profileForm.reset();
          });
        }
    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
    });
  }


  registerSubmit() {
    this.submitted = true;
    this.passwordNotMatch = false;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    if(this.registerForm.value.password != this.registerForm.value.confirmpassword){
      this.passwordNotMatch = true;
      return;
    }

    var self = this;
    var toastr = this.toastr;
    var router = this.router;
    var authService = this.authService;
    fetch(environment.apiUrl+"users/signup", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "username":this.registerForm.value.username, 
      "email":this.registerForm.value.email, 
      "password": this.registerForm.value.password
      }),
    })
    .then(
      function(response) {
        if (response.status !== 200) {
          response.json().then(function(data) {
            toastr.error(data.message, 'Error');
        });
        return;
        }
        localStorage.removeItem('logintoken');
        localStorage.removeItem(btoa("authority"));
        authService.logout();
        //$('#login button.close').trigger('click');
        toastr.success('Registration successfull. Please check your email for confirmation URL.', 'success');
        self.submitted = false;
        self.registerForm.reset();
        $('#login_form').addClass('active show');
        $('#signup_form').removeClass('active show');
        //router.navigate(['/login'])
      }
    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
    });
  }


  forgetSubmit() {
    this.forgetsubmitted = true;

    // stop here if form is invalid
    if (this.forgetForm.invalid) {
        return;
    }

    var self = this;
    var toastr = this.toastr;
    var router = this.router;
    var authService = this.authService;
    fetch(environment.apiUrl+"users/forget?username="+this.forgetForm.value.username+"&email="+this.forgetForm.value.email, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    }
    })
    .then(
      function(response) {
        if (response.status !== 200) {
          var data = response.text();
              Promise.resolve(data).then(function(value) {
                toastr.error(value);
              });
            return;
          //console.log(response);
          //response.json().then(function(data) {
            //toastr.error('Incorrect email or user name.', 'Error');
          //});
        //return;
        }
        
        toastr.success('Email sent successfully!. Check your register Email', 'success');
        self.forgetsubmitted = false;
        self.forgetForm.reset();
        $('#login_form').addClass('active show');
        $('#signup_form').removeClass('active show');
        $('#forgot_password_form').removeClass('active show');
        $('.close').trigger('click');
      }
    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
    });
  }




  redirectContactUs(){
      localStorage.setItem('contectPrevUrl', this.router.url);
      this.router.navigate(['/contact-us']);
    }

  acceptTerm(value){
    if(value.target.checked){
      this.termcondition = true;
    } else {
      this.termcondition = null;
    }
  }
  
}
