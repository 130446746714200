import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	baseUrl = environment.baseUrl;
  	constructor(private titleService:Title) {
  		this.titleService.setTitle("Court Around - Home Page");
	}

 	ngOnInit() { }

}
