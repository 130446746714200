import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { AuthService }      from '../auth/auth.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader } from '@agm/core';
import {Location} from '@angular/common';
import { environment } from '../../environments/environment';
import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  	constructor(
      public authService: AuthService, 
      private titleService:Title, 
      private toastr: ToastrService,
      private formBuilder: FormBuilder, 
      public router: Router, 
      private ActivatedRoute: ActivatedRoute,
      private mapsAPILoader: MapsAPILoader, 
      private ngZone: NgZone,
      private _location: Location) {
  		this.titleService.setTitle("Contact Us");
  	}
  	public contactUsForm = null;
  	public submitted = false;
    public emaildisabled = null;
    @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  	ngOnInit() {
   		this.contactUsForm = this.formBuilder.group({
              context: ['', [ Validators.maxLength(2000)] ],
              email: ['', [Validators.required, Validators.email]],
              message: ['', [Validators.required, Validators.maxLength(2000)]],
              recaptchaReactive: [null, Validators.required],
          });
      this.submitted = false;
      if(this.authService.isLoggedIn){
        this.authService.getUserData().then(data => {
          this.contactUsForm.controls['email'].setValue(data.email);
          this.emaildisabled = true;
        });
      }
  	}
  	get f() { return this.contactUsForm.controls; }

  	onSubmit() {

      if(localStorage.getItem('contectPrevUrl') != '' && localStorage.getItem('contectPrevUrl')){
        var body = {
          "contact":this.contactUsForm.value.email,
          "message":this.contactUsForm.value.message,
          "context":localStorage.getItem('contectPrevUrl')
        };
        var redirectUrl = localStorage.getItem('contectPrevUrl');
      } else {
        var body = {
          "contact":this.contactUsForm.value.email,
          "message":this.contactUsForm.value.message,
          "context":'User Direct Hit this Url'
        };
         var redirectUrl = '/';
      }


      var self = this;
      this.submitted = true;
      var propertyValues = [];
        var token = localStorage.getItem('logintoken');
    	this.submitted = true;
      if (this.contactUsForm.invalid) {
          return;
      }
      fetch(environment.apiUrl+"api/app/feedback/create", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer '+token,
        },
        body: JSON.stringify(body),
      })
      .then(
        function(response) {
          if (response.status !== 200) {
              response.json().then(function(data) {
                self.toastr.error(data.message, 'Error');
            });
          return;
          } else {
             self.toastr.success('Mail sent Successfully', 'success');
             self.redirctPath(redirectUrl);
          }
          
      }
      )
      .catch(function(err) {
          console.log('Fetch Error :-S', err);
      });
    }
    redirctPath(url){
      if((url.indexOf('?') !== -1)){
        var beforeUrl = url.split('?')[0];
        if(url.indexOf('search') !== -1 && url.indexOf('resource') !== -1){
          this.router.navigate([beforeUrl], { queryParams: { search: 'true', resource: localStorage.getItem('eventDetailResourceType') } });
        } else if(url.indexOf('search') !== -1){
          this.router.navigate([beforeUrl], { queryParams: { search: 'true' } });
        } else if(url.indexOf('resource') !== -1){
          this.router.navigate([beforeUrl], { queryParams: { resource: localStorage.getItem('eventDetailResourceType') } });
        } else {
          this.router.navigate([beforeUrl]);
        }
      } else {
        this.router.navigate([url]);
      }
    }

}
