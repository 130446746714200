import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-reset-account',
  templateUrl: './reset-account.component.html',
  styleUrls: ['./reset-account.component.css']
})
export class ResetAccountComponent implements OnInit {

  constructor(private route: ActivatedRoute,private formBuilder: FormBuilder,public router: Router) { }
  public formControlMain: FormGroup;
  public message = null;
  public token = null;
  ngOnInit() {
    var self = this;
    this.formControlMain = this.formBuilder.group({
      message: ['', Validators.required]
  });
  this.token = this.route.snapshot.queryParamMap.get("token");
  fetch(environment.apiUrl+"users/confirm-reset?token="+this.token, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    }
})
.then(
    function(response) {
        if (response.status !== 200) {
            response.json().then(function(data) {
                self.message='Error during account reset. Please contact administrator.';
            });
            return;
        }
          response.json().then(function(data) {
              self.message='Account successfully reset. Please check email for temporary password.'
            }
            )
        }
)
  }

}
