import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService }      from '../auth/auth.service';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {

  constructor(private authService:AuthService) { }
  public termText = null;

  ngOnInit() {
    var self = this;
  		
    var url = environment.apiUrl+"api/utility/terms";
    fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/text',
        }
     }).then(
        function(response) {
            if (response.status !== 200) {
                response.text().then(function(data) {
              });
              return;
            }
            response.text().then(function(data) {
              self.termText=data;
            });
        }
      )
      .catch(function(err) {
          console.log('Fetch Error :-S', err);
      });
    }

}
