import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService }      from '../auth/auth.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private authService:AuthService) { }
  public policyText = null;
  ngOnInit() {
    var self = this;
  		
  			var url = environment.apiUrl+"api/utility/policy";
  			fetch(url, {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/text',
		        }
	     	}).then(
		        function(response) {
		          	if (response.status !== 200) {
		              	response.text().then(function(data) {
		            	});
		          		return;
		          	}
		          	response.text().then(function(data) {
                  self.policyText=data;
		          	});
		        }
	      	)
	      	.catch(function(err) {
	          	console.log('Fetch Error :-S', err);
	      	});
  }
  

}
