import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationExtras } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.css']
})
export class PageFooterComponent implements OnInit {
	  submitted = false;
    newseltterForm = null;
    baseUrl = environment.baseUrl;
  	constructor(private toastr: ToastrService, private formBuilder: FormBuilder, public router: Router) { }

  	ngOnInit() {
	  	this.newseltterForm = this.formBuilder.group({
	        email: ['', [Validators.required, Validators.email]],
	    });
  	}

  	get f() { return this.newseltterForm.controls; }

  	onSubmit() { 
  		this.submitted = true;
        if (this.newseltterForm.invalid) {
            return;
        }
        console.log(this.newseltterForm.value.email);
    }

    redirectContactUs(){
      localStorage.setItem('contectPrevUrl', this.router.url);
      this.router.navigate(['/contact-us']);
    }
}
