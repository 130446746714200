import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';

import { LoginComponent }    from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { RegisterComponent } from './register/register.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileEditComponent } from './user-profile-edit/user-profile-edit.component';
import {MatNativeDateModule} from '@angular/material/core';
import {DemoMaterialModule} from '../material-module';
import { UserProfileWithIdComponent } from './user-profile-with-id/user-profile-with-id.component';
import { UserProfileEditWithIdComponent } from './user-profile-edit-with-id/user-profile-edit-with-id.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { ResetAccountComponent } from './reset-account/reset-account.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwukc4DMGb5rVJ0vHJL_p4RIp4qRzOR9Q',
      libraries: ["places"]
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    MatNativeDateModule,
    DemoMaterialModule
  ],
  declarations: [
    LoginComponent,
    RegisterComponent,
    UserProfileComponent,
    UserProfileEditComponent,
    UserProfileWithIdComponent,
    UserProfileEditWithIdComponent,
    ConfirmAccountComponent,
    ResetAccountComponent
  ],
   providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LeHyNcUAAAAAEgFszwE3YxbQmMU2Cmzro8Smn_G',
    } as RecaptchaSettings,
  }],
})
export class AuthModule {}
