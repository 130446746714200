import { NgModule }       from '@angular/core';
import { BrowserModule }  from '@angular/platform-browser';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { Router } from '@angular/router';

import { AppComponent }            from './app.component';
import { PageNotFoundComponent }   from './page-not-found/page-not-found.component';

import { AppRoutingModule }        from './app-routing.module';
import { AuthModule }              from './auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HomeComponent } from './home/home.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
//import { CourtsComponent } from './courts/courts.component';
//import { eventsComponent } from './events/events.component';
//import { ReservationsComponent } from './reservations/reservations.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InviteComponent } from './invite/invite.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import {  MatProgressBarModule } from '@angular/material';
import { IsLoadingService, IsLoadingModule } from '@service-work/is-loading';
import { ScheduleComponent } from './schedule/schedule.component';
import { FacilityScheduleComponent } from './facility-schedule/facility-schedule.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
//import { ScheduleCreateComponent } from './schedule/schedule-create/schedule-create.component';
//import { ScheduleEditComponent } from './schedule/schedule-edit/schedule-edit.component';
// import { eventsDetailComponent } from './events/events-detail/events-detail.component';
// import { eventsEditComponent } from './events/events-edit/events-edit.component';
// import { eventsCreateComponent } from './events/events-create/events-create.component';
// import { eventsListComponent } from './events/events-list/events-list.component';
// import { ReservationsDetailComponent } from './reservations/reservations-detail/reservations-detail.component';
// import { ReservationsListComponent } from './reservations/reservations-list/reservations-list.component';
// import { CourtsDetailComponent } from './courts/courts-detail/courts-detail.component';
// import { CourtsListComponent } from './courts/courts-list/courts-list.component';
//import { FacilitiesComponent } from './facilities/facilities.component';
//import { FacilitiesListComponent } from './facilities/facilities-list/facilities-list.component';
import { HttpClientModule,HttpHeaders, HttpClient } from '@angular/common/http';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AuthModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwukc4DMGb5rVJ0vHJL_p4RIp4qRzOR9Q',
      libraries: ["places"]
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    MatProgressBarModule , IsLoadingModule,
    HttpClientModule
  ],
  declarations: [
    FacilityScheduleComponent,
    ScheduleComponent,
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    //CourtsComponent,
    //eventsComponent,
    //ReservationsComponent,
    PageHeaderComponent,
    PageFooterComponent,
    ContactUsComponent,
    InviteComponent,
    PrivacyPolicyComponent,
    TermConditionComponent,
    TermsConditionsComponent,
    //ScheduleCreateComponent,
    //ScheduleEditComponent,
    // eventsDetailComponent,
    // eventsEditComponent,
    // eventsCreateComponent,
    // eventsListComponent,
    // ReservationsDetailComponent,
    // ReservationsListComponent,
    // CourtsDetailComponent,
    // CourtsListComponent,
    //FacilitiesComponent,
    //FacilitiesListComponent
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LeHyNcUAAAAAEgFszwE3YxbQmMU2Cmzro8Smn_G',
    } as RecaptchaSettings,
  },
  { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } } 
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
}
