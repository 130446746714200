import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  	constructor(private titleService:Title) {
  		this.titleService.setTitle("Schedule Page");
	}

  ngOnInit() {
  }

}
