import { Component }        from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AuthService }      from '../auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css','../../../frontend-theme.css']
})
export class LoginComponent {
  message: string;
  profileForm = new FormGroup({
    email: new FormControl('', Validators.compose([
        Validators.required,
        //Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('',[Validators.required,]),
  });

  constructor(public authService: AuthService, public router: Router, private toastr: ToastrService) {
    this.setMessage();
  }

  setMessage() {
    this.message = 'Logged ' + (this.authService.isLoggedIn ? 'in' : 'out');
  }

  onSubmit() {
    var auth = this.authService;
    var router = this.router;
    var toastr = this.toastr;
    this.message = 'Trying to log in ...';
    // localStorage.setItem('logintoken', 'dhgdfsgfdsfdsf');
    // localStorage.setItem(btoa("authority"), btoa('ADMIN'));
    fetch(environment.baseUrl+"/users/signin?username="+this.profileForm.value.email+"&password="+this.profileForm.value.password, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(
        function(response) {
          if (response.status !== 200) {
            response.json().then(function(data) {
		      		toastr.error(data.message, 'Error');
			    });
            return;
          }
          response.json().then(function(data) {
            localStorage.setItem('logintoken', data.token);
            localStorage.setItem(btoa("authority"), btoa(data.roles[0].authority));
            //toastr.success('Login Successfully', 'success');
            auth.login().subscribe(async () => {
            if (auth.isLoggedIn) {
              let redirect = router.parseUrl(auth.redirectUrl ? auth.redirectUrl : await auth.getHome());
              let navigationExtras: NavigationExtras = {
                queryParamsHandling: 'preserve',
                preserveFragment: true
              };

              // Redirect the user
              setTimeout(() => router.navigateByUrl(redirect, navigationExtras), 10);
            }
          });
          });
        }
    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
    });
  }
}
