import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-facility-schedule',
  templateUrl: './facility-schedule.component.html',
  styleUrls: ['./facility-schedule.component.css']
})
export class FacilityScheduleComponent implements OnInit {

  	constructor(private titleService:Title) {
  		this.titleService.setTitle("Schedule Page");
	}

  ngOnInit() {
  }

}
