import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';
import { Router, NavigationExtras } from '@angular/router';
import { AuthService }      from './auth/auth.service';
import { AdminService }      from './admin/admin.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
  animations: [ slideInAnimation ]
})
export class AppComponent {
  constructor(public authService: AuthService,public adminService: AdminService, public router: Router, private toastr: ToastrService) {
   // this.setMessage();
  }
  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  logout() {
    localStorage.removeItem('logintoken');
    localStorage.removeItem(btoa("authority"));
    this.authService.logout();
    this.toastr.success('Logout successfully!', 'success');
    this.router.navigate(['/login']);
  }

  moveToSection(sectionId){
    $([document.documentElement, document.body]).animate({
        scrollTop: $("#"+sectionId).offset().top
    }, 1000);
  }
  ngOnInit() { }
}
