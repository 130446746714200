import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService }      from '../auth.service';
import { Router } from "@angular/router"
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css','../../../frontend-theme.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
    submitted = false;
    passwordNotMatch = false;

    constructor(private formBuilder: FormBuilder,private toastr: ToastrService, public authService: AuthService,private router: Router) { }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            username: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', [Validators.required,Validators.minLength(8)]],
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.passwordNotMatch = false;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        if(this.registerForm.value.password != this.registerForm.value.confirmPassword){
        	this.passwordNotMatch = true;
        	return;
        }

        // display form values on success
        var toastr = this.toastr;
	  	var router = this.router;
	  	var authService = this.authService;
	  	fetch(environment.baseUrl+"/users/signup", {
		  method: 'POST',
		  headers: {
		    'Content-Type': 'application/json',
		  },
		  body: JSON.stringify({
	      	"username":this.registerForm.value.username, 
	      	"email":this.registerForm.value.email, 
	      	"password": this.registerForm.value.password
	      	}),
		})
		.then(
		    function(response) {
	      	if (response.status !== 200) {
		      	response.json().then(function(data) {
		      		toastr.error(data.message, 'Error');
			    });
	        return;
	      	}
	      	localStorage.removeItem('logintoken');
		    localStorage.removeItem(btoa("authority"));
		    authService.logout();
	      	toastr.success('Registration successfull. Please check your email for confirmation URL.', 'success');
		    router.navigate(['/login'])
	    }
		)
		.catch(function(err) {
		    console.log('Fetch Error :-S', err);
		});
    }

    onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }
}
