import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {Title} from "@angular/platform-browser";
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { AuthService }      from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-user-profile-with-id',
  templateUrl: './user-profile-with-id.component.html',
  styleUrls: ['./user-profile-with-id.component.css']
})
export class UserProfileWithIdComponent implements OnInit {

  	constructor(
  		private authService:AuthService, 
  		private titleService:Title, 
  		private toastr: ToastrService, 
  		public router: Router,
  		private ActivatedRoute: ActivatedRoute,
  		) { 
  		this.titleService.setTitle("User Profile");
  	}
  	public userInfo = null;
  	public propertiesGroupIds = []; 
	public propertiesGroup = []; 
  	public userId = null;

  	ngOnInit() {
  		var self = this;
  		this.userId = this.ActivatedRoute.snapshot.paramMap.get('id');
  		var token = localStorage.getItem('logintoken');
  		var userdata = localStorage.getItem('userdata');
  		var info = JSON.parse(userdata);
  		if(info.id == this.userId || this.authService.isAdmin){
  			fetch(environment.apiUrl+"users/"+this.userId, {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
		          'Authorization': 'Bearer '+token,
		        }
	     	}).then(
		        function(response) {
		          	if (response.status !== 200) {
		              	response.json().then(function(data) {
		            	});
		          		return;
		          	}
		          	response.json().then(function(data) {
		          		console.log(data);
		          		self.userInfo = data;
		          		for (const d of (data.propertyValues as any)) {
				  			var propertyItem = d.propertyAssignment;
							if( !self.propertiesGroupIds.includes(propertyItem.propertyGroup.id) ) {
								console.log(propertyItem.propertyGroup.id);
								let prop= {
									"id": propertyItem.propertyGroup.id, 
									"name":propertyItem.propertyGroup.name, 
									"properties": [d]
								};
								self.propertiesGroup.push(prop);
								self.propertiesGroupIds.push(propertyItem.propertyGroup.id);
							} else {
								for (const f of (self.propertiesGroup as any)) {
									if(f.id == propertyItem.propertyGroup.id){
										f.properties.push(d);
									}
								}
							}
				  		}
		          	});
		        }
	      	)
	      	.catch(function(err) {
	          	console.log('Fetch Error :-S', err);
	      	});
	    } else {
	    	self.toastr.error('Not a Valid User', 'Error');
			self.router.navigate(['/home']);
	    } 
	  	
  	}

  	propertyCount(prop){
  		for (const d of (prop as any)) {
  			if(d.value && d.value != 'null'){
  				return true;
  			}
  		}
  		return false;
  	}

  	activeUser(value){
  		var self = this;
  		var token = localStorage.getItem('logintoken');
  		if(value== false){
  			var url = environment.apiUrl+"users/deactivate?userId="+this.userId;
  		} else {
  			var url = environment.apiUrl+"users/activate?userId="+this.userId;
  		}
  		fetch(url, {
	        method: 'POST',
	        headers: {
	          'Content-Type': 'application/json',
	          'Authorization': 'Bearer '+token,
	        }
     	}).then(
	        function(response) {
	          	if (response.status !== 200) {
	              	response.json().then(function(data) {
	            	});
	          		return;
	          	} else {
	          		self.toastr.success('Update Successfully', 'success');
	          		self.ngOnInit();
	          	}
	          
	        }
      	)
      	.catch(function(err) {
          	console.log('Fetch Error :-S', err);
      	});
  	}

}
