import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { Router, NavigationExtras } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

    constructor(public authService: AuthService, private titleService: Title, private toastr: ToastrService, public router: Router) {
        this.titleService.setTitle("User Profile");
    }
    public userInfo = null;
    public propertiesGroupIds = [];
    public propertiesGroup = [];

    ngOnInit() {
        var self = this;
        var token = localStorage.getItem('logintoken');
        var userdata = localStorage.getItem('userdata');
        var info = JSON.parse(userdata);
        if (this.authService.isAdmin) {
            var url = environment.apiUrl + "users/" + info.id;
        } else {
            var url = environment.apiUrl + "users/me";
        }
        if (info) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(
                function (response) {
                    if (response.status !== 200) {
                        response.json().then(function (data) {
                        });
                        return;
                    }
                    response.json().then(function (data) {
                        console.log(data)
                        self.userInfo = data;
                        for (const d of (data.propertyValues as any)) {
                            var propertyItem = d.propertyAssignment;
                            if (!self.propertiesGroupIds.includes(propertyItem.propertyGroup.id)) {
                                console.log(propertyItem.propertyGroup.id);
                                let prop = {
                                    "id": propertyItem.propertyGroup.id,
                                    "name": propertyItem.propertyGroup.name,
                                    "properties": [d]
                                };
                                self.propertiesGroup.push(prop);
                                self.propertiesGroupIds.push(propertyItem.propertyGroup.id);
                            } else {
                                for (const f of (self.propertiesGroup as any)) {
                                    if (f.id == propertyItem.propertyGroup.id) {
                                        f.properties.push(d);
                                        f.properties.sort((a, b) => (a.propertyAssignment.objectOrder > b.propertyAssignment.objectOrder) ? 1 : -1);
                                    }
                                }
                            }
                        }
                    });
                }
            )
                .catch(function (err) {
                    console.log('Fetch Error :-S', err);
                });
        } else {
            self.toastr.error('Please LogIn First!', 'Error');
            self.router.navigate(['/home']);
        }

    }

    propertyCount(prop) {
        for (const d of (prop as any)) {
            if (d.value && d.value != 'null') {
                return true;
            }
        }
        return false;
    }

    deleteMe() {
        let result = confirm("Are you sure you want to delete your account and all the information related to it? Once deleted, it cannot be undone.");
        if (result) {
            let thus = this;
            fetch(environment.apiUrl + "users/me/delete", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('logintoken'),
                }
            })
                .then(
                    function (response) {
                        if (response.status !== 200) {
                            response.json().then(function (data) {
                                thus.toastr.error(data.message, 'Error');
                            });
                            return;
                        }
                        thus.toastr.success('Deleted Successfully', 'success');
                        thus.logout()
                    }
                )
                .catch(function (err) {
                    console.log('Fetch Error :-S', err);
                });
        }
    }

    logout() {
        localStorage.removeItem('logintoken');
        localStorage.removeItem('userdata');
        localStorage.removeItem(btoa("authority"));
        this.authService.logout();
        this.toastr.success('Logout successfully!', 'success');
        this.router.navigate(['/']);
    }
}
