import { Injectable }       from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras,
  CanLoad, Route
}                           from '@angular/router';
import { AuthService }      from '../auth/auth.service';
import { AdminService }      from './admin.service';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private adminService: AdminService,private authService: AuthService, private router: Router, private toastr: ToastrService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    let url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    console.log(this.authService.isAdmin);
    if (this.authService.isAdmin) { return true; }
    if (this.authService.isLoggedIn) { 
      this.toastr.error('Not a valid User!', 'Error');
      this.router.navigate(['/home']);
    } else {
      this.toastr.error('Please Login First!', 'Error');
      this.router.navigate(['/login']);
    }
    return false;
  }
}
