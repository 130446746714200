import { Component, VERSION, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MapsAPILoader } from '@agm/core';
import { AuthService }      from '../../auth/auth.service';
import {Location} from '@angular/common';
declare var google;

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.css']
})
export class UserProfileEditComponent implements OnInit {
	constructor(
  		public authService: AuthService,
      private toastr: ToastrService, 
      private formBuilder: FormBuilder, 
      public router: Router, 
      private titleService:Title,
      private mapsAPILoader: MapsAPILoader,
      private ngZone: NgZone,
      private ActivatedRoute: ActivatedRoute,
      private _location: Location
      ) {
      this.titleService.setTitle("Edit User Profile");
  	}
  	@ViewChild('searchFrom', {static: false}) searchFormElementRef: ElementRef;
  	@ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

  	public baseUrl = environment.baseUrl;
    public submitted = false;
    public confirmPasswordText = false;
    public ajaxStart = false;
    public editId = null;
    public allData = null;
    public formControlMain = null;
    public dataChanges = false;
    public mainFormData = null;
    public version = VERSION.full;
    public userData = null;

    public propertiesGroupIds = []; 
    public propertiesGroup = []; 
  	
  	ngOnInit() {
  		var self = this;
      var token = localStorage.getItem('logintoken');

       /**Main Form Control**/
      this.formControlMain = this.formBuilder.group({
            password: [null],
            newPassword: [null],
            confirmPassword: [null],
            recaptchaReactive: [null, Validators.required],
        });


  		/** Get Current User Data **/
      this.authService.getUserData().then(data => {
          this.userData = data;
          self.allData = data;
          var groupData = {};
          self.propertiesGroup = [];
          self.propertiesGroupIds = [];
          for (const d of (data.propertyValues as any)) {
            /***For grouping Data ***/
            var propertyItem = d.propertyAssignment;
            if( !self.propertiesGroupIds.includes(propertyItem.propertyGroup.id) ) {
              let prop= {
                "id": propertyItem.propertyGroup.id, 
                "name":propertyItem.propertyGroup.name, 
                "properties": [d]
              };
              self.propertiesGroup.push(prop);
              self.propertiesGroupIds.push(propertyItem.propertyGroup.id);
            } else {
              for (const f of (self.propertiesGroup as any)) {
                if(f.id == propertyItem.propertyGroup.id){
                  f.properties.push(d);
                  f.properties.sort((a, b) => (a.propertyAssignment.objectOrder > b.propertyAssignment.objectOrder) ? 1 : -1);
                }
              }
            }
            /***For Create Form and Validation ***/
            var item = propertyItem;
            var validation = [];
            var defaultValue = "";
            if(item.required){
              validation.push(Validators.required);
            }
            if(item.property.defaultValue && item.property.defaultValue != 'NONE'){
              defaultValue = item.property.defaultValue;
            }
            if(item.property.dataType == 'NUMBER'){
              validation.push(Validators.pattern('[0-9]+'));
            }
            if(item.property.minValue){
              validation.push(Validators.min(item.property.minValue));
            }
            if(item.property.maxValue){
              validation.push(Validators.max(item.property.maxValue));
            }
            if(item.property.dataType == 'DATE'){
              if(d.value != null && d.value != ''){
                groupData["name"+item.property.id+item.propertyGroup.id] = [new Date(d.value), validation];
              } else {
                groupData["name"+item.property.id+item.propertyGroup.id] = ['', validation];
              }
            } else if(item.property.dataType == 'STRING' && item.property.listOfValues && item.multiValue){
              var multi = d.value;
              if(d.value != null){
                multi = d.value.split('<!>');
              }
              groupData["name"+item.property.id+item.propertyGroup.id] = [multi, validation];
            } else {
              groupData["name"+item.property.id+item.propertyGroup.id] = [d.value, validation];
            }
          }
          console.log(groupData);
          groupData['recaptchaReactive'] = [null, Validators.required];
          groupData['password'] = [null,];
          groupData['newPassword'] = [null, ];
          groupData['confirmPassword'] = [null,];
          self.formControlMain = self.formBuilder.group(groupData);
          self.ajaxStart = false;
          self.mainFormData = self.formControlMain.value;
          self.propertiesGroup.sort((a, b) => (a.id < b.id) ? -1 : ((a.id > b.id) ? 1 : 0));
      });
	  	
  	}
  	get f() { return this.formControlMain.controls; }

  	ngDoCheck(){
      if(this.mainFormData != null){
        var change = 0;
        var inputKeys = Object.keys(this.formControlMain.value);
        for (const d of (inputKeys as any)) {
          if(this.mainFormData[d] !== this.formControlMain.value[d]){
            change = 1;
          }
        }
        if(change == 1){
          this.dataChanges = true;
        } else {
          this.dataChanges = false;
        }
      }
    }

    backClicked() {
      this._location.back();
    }

    /** Control Radio Data **/
    clickRedioProperty(propertyName, value){
      this.formControlMain.controls[propertyName].setValue(value);
    }
    /** Submit Form **/
  onSubmit() {
    var self = this;
    this.submitted = true;
    this.confirmPasswordText = false;
    var propertyValues = [];
    var token = localStorage.getItem('logintoken');
    if (this.formControlMain.invalid) {
      console.log('invalid');
       return;
    } else {
      $('.submit-class').prop('disabled',true);
    } 
    if(self.formControlMain.value.newPassword != self.formControlMain.value.confirmPassword){
      this.confirmPasswordText = true;
      return;
    }

    
    /** Text & Date**/
    $('#property-fields input[type="text"]').each(function(){
      var obj = JSON.parse($(this).attr('data'));
      obj.value = "";
      obj.value = $(this).val();
      propertyValues.push(obj);
    });
    
    /** Textarea **/
    $('#property-fields textarea').each(function(){
      var obj = JSON.parse($(this).attr('data'));
      obj.value = "";
      obj.value = $(this).val();
      propertyValues.push(obj);
    });
    
    /** Number **/
    $('#property-fields input[type="number"]').each(function(){
      var obj = JSON.parse($(this).attr('data'));
      obj.value = null;
      obj.value = parseInt($(this).val());
      propertyValues.push(obj);
    });

    /** Select **/
    $('#property-fields select').each(function(){
          var obj = JSON.parse($(this).attr('data'));
      obj.value = null;
      if(Array.isArray($(this).val())){
        var fname = $(this).attr('data-name');
        var val = self.formControlMain.value[fname];
        if(val != null){
          if(val.length > 0){
            obj.value = val.join('<!>');
          }
        }
      } else {
        obj.value = $(this).val();
      }
          propertyValues.push(obj);
        });
    
    /** Redio **/
    var inputRedioName = [];
    $('#property-fields input[type="radio"]').each(function(){
      inputRedioName.push($(this).attr('name'));
    });
    inputRedioName = inputRedioName.filter((v, i, a) => a.indexOf(v) === i);
    console.log(inputRedioName);
    for (const item of (inputRedioName as any)) {
      var obj = JSON.parse($('#property-fields input[name="'+item+'"]').attr('data'));
      if($('#property-fields input[name="'+item+'"]:checked').val() == "true"){
        obj.value = true;
      } else if($('#property-fields input[name="'+item+'"]:checked').val() == "false"){
        obj.value = false;
      } else {
        obj.value = null;
      }
      propertyValues.push(obj);
    }
    console.log('herer');
    self.allData.propertyValues = propertyValues;
    console.log(self.allData);
    if(self.formControlMain.value.password != '' && self.formControlMain.value.newPassword !=''){
      var d = {'password':self.formControlMain.value.password,'newPassword':self.formControlMain.value.newPassword,'propertyValues':propertyValues} 
    } else {
      var d = {'password':null,'newPassword':null,'propertyValues':propertyValues};
    }
    fetch(environment.apiUrl+"users/me/update", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      },
      body: JSON.stringify(d),
    })
    .then(
        function(response) {
          if (response.status !== 200) {
              var data = response.text();
              Promise.resolve(data).then(function(value) {
                self.toastr.error(value);
                $('.submit-class').prop('disabled',false);
              });
            return;
          } else {
             self.toastr.success('Update Successfully', 'success');
              self.router.navigate(['/user-profile']);
              self.authService.getHome();
            // response.json().then(function(data) {
            //   self.toastr.success('Update Successfully', 'success');
            //   self.router.navigate(['/user-profile']);
            // });
          }
          
      }
    )
    .catch(function(err) {
        console.log('Fetch Error :-S', err);
    });
  }
}
